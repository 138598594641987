
.guess_word {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}
li.guess_letter {
    width: 40px;
    height: 50px;
    line-height: 50px;
    margin: 5px;
    list-style: none;
    font-size: 2em;
    transition: background 1s, color 1s;
    border-radius: 3px;
}

li.guess_letter:nth-child(1) {
    transition-delay: .2s;
}
li.guess_letter:nth-child(2) {
    transition-delay: 0.7s;
}
li.guess_letter:nth-child(3) {
    transition-delay: 1.2s;
}
li.guess_letter:nth-child(4) {
    transition-delay:  1.7s;
}
li.guess_letter:nth-child(5) {
    transition-delay: 2.2s;
}

.guess_letter.clean {
    background-color: #f0f0f0;
}

.guess_letter.correct, .ipa_button.correct {
    background-color: #4CAF50;   
    color: rgba(255,255,255,0.87)
}

.guess_letter.incorrect, .ipa_button.incorrect {
    background-color: gray;
    color: rgba(255,255,255,0.87)
}

.guess_letter.partial, .ipa_button.partial {
    background-color: #FFEB3B;
    color: rgba(0,0,0,0.87)
}

.ipa_button {
    height: 60px;
    line-height: 60px;
    font-size: 1.5em;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    flex: 1;
    transition: background 1s, color 1s;
    transition-delay: 2.7s;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
    cursor: pointer;
    user-select: none;
}

.keyboard {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 5px;
    justify-content: center;
    max-height: 255px;
    margin: 15px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    right: 0;
    background-color: wheat;
    opacity: 0;
    transition: opacity 1s;
    transition-delay: 3.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
}

.modal p {
    margin: 1rem;
}

.modal .words {
    font-size: 2rem;
}

.modal.visible {
    opacity: 1;
}

.emoji_share {
    font-size: 1rem;
}
.emoji_share p {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
}

.about {
    padding: 1em;
    background-color: #fafafa;
    position: fixed;
    left: 0px;
    top: 0;
    height: 100vh;
    width: 100vw;
    text-align: left;
    transform: translate3d(-100vw, 0, 0);
    transition: transform 1s ease-in;
    z-index: 2;
}

.about.show {
    left: 0;
    transform: translate3d(0, 0, 0);
}